<template>
<!-- eslint-disable max-len -->
  <div class="col bg-light order-page">
    <div class="row d-flex justify-content-center pt-3">
      <div class="col-sm-12 col-md-5">
        <multiselect
          placeholder="Filter by activity" label="title" track-by="uuid"
          v-model="selectActivity"
          :options="activities"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preselect-first="false"
          :disabled="isLoadingOrders"
          >
        </multiselect>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col">
        <div class="wrapper table-responsive bg-white pb-3">
          <table class="table table-hover table-borderless pl-3">
            <thead>
              <tr class="border-bottom">
                <th scope="col" class="pl-3">
                  Order ID
                </th>
                <th scope="col">
                  Date
                </th>
                <th scope="col">
                  Event Name
                </th>
                <th scope="col">
                  Sold Tickets
                </th>
                <th scope="col">
                  Total Revenue
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoadingOrders">
                <tr class="border-bottom">
                  <td />
                  <td />
                  <td class="text-right">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
              </template>
              <template v-else>
                <template v-for="item in items">
                  <tr
                    :key="item.uuid"
                    class="border-bottom"
                  >
                    <td class="text-secondary pl-4">
                      {{ `#${item.ref}` }}
                    </td>
                    <td class="text-secondary">
                      {{ parseOrderDate(item.created) }}
                    </td>
                    <td class="text-secondary">
                      {{ item.event.title }}
                    </td>
                    <td class="text-secondary px-4">
                      {{ item.bookings.length }} Pcs
                    </td>
                    <td class="text-dark">
                      <span
                        class="rounded-pill px-3 text-center revenue-pill"
                      >
                        {{ getEarnings(item) }}
                      </span>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';

import Utils from '@/utils';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      page: 1,
      pageSize: 20,
      selectActivity: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getItems();
      next();
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.getItems();
    next();
  },

  computed: {
    ...mapState('orders', {
      items: 'items',
      isLoadingOrders: 'isLoading',
    }),

    ...mapState('events', {
      activities: 'events',
    }),

    eventTitle() {
      const { eventTitle } = this.$route.query;
      return eventTitle && eventTitle.trim() ? eventTitle.trim() : null;
    },
  },

  watch: {
    selectActivity(newVal, oldVal) {
      if (newVal !== oldVal) {
        const event = newVal ? newVal.uuid : null;
        this.getItems(null, null, event);
      }
    },
  },

  methods: {
    getItems(page, pageSize, event) {
      let loadPage = page;
      let loadPageSize = pageSize;
      let loadEvent = event;
      const eventId = this.getEventId();
      if (!loadPage) {
        loadPage = this.page;
      }
      if (!loadPageSize) {
        loadPageSize = this.pageSize;
      }
      if (!loadEvent && eventId) {
        loadEvent = eventId;
        this.selectActivity = {
          uuid: eventId,
          title: this.eventTitle,
        };
      }

      return this.$store.dispatch(
        'orders/getOrders',
        {
          page: loadPage,
          pageSize: loadPageSize,
          event: loadEvent,
        },
      )
        .finally(() => {
          if (this.getEventId()) {
            this.$set(this.$route.query, 'eventId', null);
          }

          this.$store.dispatch(
            'events/getEventNames',
            {
              hasOrders: true,
            },
          );
        });
    },

    getEventId() {
      const { eventId } = this.$route.query;
      return eventId && eventId.trim() ? eventId.trim() : null;
    },

    getEarnings(order) {
      let earnings = 0;
      let currency = null;
      order.bookings.forEach((booking) => {
        const { ticket } = booking;
        earnings += Number(ticket.price);
        if (!currency && ticket.price_currency) {
          currency = ticket.price_currency;
        }
      });
      return `${currency} ${earnings}`;
    },

    parseOrderDate(datestring) {
      return Utils.parseISOString(datestring);
    },

    scroll() {
      console.debug('on scrolled');
      const scrolledTo = document.querySelector('.page-end');

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        console.debug('page end');
        if (!this.loading) {
          const lastElement = this.page * this.pageSize;
          const page = this.page + 1;
          this.getItems({ page })
            .then((response) => {
              if (response.success) {
                this.page += 1;
              }
              const el = this.$el.querySelector(`.event-row:nth-child(${lastElement})`);
              console.debug(`scrolling to view ${el}`);
              if (el) {
                el.scrollIntoView({ behaviour: 'smooth' });
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.order-page {
  .wrapper {
    overflow: auto;
    border-radius: 30px !important;
  }

  table {
    thead, tr, td {
      line-height: 3.5rem;
    }

    thead {
      font-size: 1.05rem;
    }

    .revenue-pill {
      display: inline-block;
      line-height: 2.75rem;
      min-width: 50%;
      background-color: $wham-icon-bg-color;
      color: $wham-blue-color;
    }

    @media (max-width: 994px) {
      thead, tr, td {
        line-height: 1.5rem;
      }

      thead {
        font-size: 1rem;
      }

      .revenue-pill {
        display: inline-block;
        line-height: 1.5rem;
        min-width: 60%;
        background-color: $wham-icon-bg-color;
        color: $wham-blue-color;
      }
    }
  }
}
</style>
